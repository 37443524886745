import { IFormData, ILoginReq } from "interfaces/authentication";

export interface ValidationResult {
    isValid: boolean;
    errorMessage: string;
}

const fieldLabels: Record<string, string> = {
    name: "Full Name",
    businessFullName: "Business Full Name",
    email: "Email",
    pin: "PIN",
    confirmPin: "Confirm PIN",
    mobile: "Mobile number",
};

const errorMessages = {
    required: (field: string) => `${field} is required.`,
    invalidEmail: "Please enter a valid email address.",
    pinLength: "PIN must be at least 8 digits long.",
    pinMismatch: "The PINs do not match.",
    enterPinFirst: "Please enter your PIN first.",
};

const emailRegex = /^[a-zA-Z0-9](\.?[a-zA-Z0-9_-]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
const isEmpty = (value: string) => !value.trim();

export const validateInput = (
    value: string,
    field: string,
    formData?: IFormData | ILoginReq
): ValidationResult => { //NOSONAR
    let errorMessage = "";

    const fieldLabel = fieldLabels[field] || field;
    if (isEmpty(value)) {
        if (field === "confirmPin") {
            if (!formData?.pin) {
                errorMessage = errorMessages.enterPinFirst;
            } else {
                errorMessage = errorMessages.required(fieldLabel);
            }
        } else {
            errorMessage = errorMessages.required(fieldLabel);
        }
    } else {
        switch (field) {
            case "email":
                if (!emailRegex.test(value)) {
                    errorMessage = errorMessages.invalidEmail;
                }
                break;

            case "pin":
                if (!/^\d{8,}$/.test(value)) {
                    errorMessage = errorMessages.pinLength;
                }
                break;

            case "confirmPin":
                if (formData?.pin && value !== formData.pin) {
                    errorMessage = errorMessages.pinMismatch;
                }
                break;
        }
    }

    return {
        isValid: !errorMessage,
        errorMessage,
    };
};
