import { Link } from 'react-router-dom';
import './GetStarted.css';
import { CONSTANT_ROUTES } from 'constants/ConstantRoutes';

const GetStarted = () => {
  return (
    <div className="container">
      <div className="get-started-text">
        <h2>Ready to accept payments anytime, anywhere?</h2>
      </div>
      <div className="navigate-use-btn" >
        <Link className='get-started-btn ' to={CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.USER.SIGNUP}>Get Started</Link>
      </div>
    </div>
  );
};

export default GetStarted;