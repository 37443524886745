import emailjs from '@emailjs/browser';
import { CloseOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import './BusinessForm.css';

interface BusinessFormProps {
  title?: string | null;
  requiredFields?: string[];
  placeholders?: { [key: string]: string };
  isModal: boolean;
  handleCancel?: () => void;
}

const BusinessForm = ({ title, requiredFields = [], placeholders = {}, isModal, handleCancel }: BusinessFormProps) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [relationshipSelected, setRelationshipSelected] = useState(false);

  const form = useRef<HTMLFormElement>(null);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    websiteURL: '',
    location: '',
    email: '',
    company: '',
    relationship: '',
    comments: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setRelationshipSelected(true);
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!form.current) return;

    const SERVICE_ID : string = process.env.REACT_APP_MAILJS_SERVICE_ID!;
    const PUBLIC_KEY : string = process.env.REACT_APP_MAILJS_PUBLIC_KEY!;
    const TEMPLET_ID : string = process.env.REACT_APP_MAILJS_TEMPLET_ID!;

    emailjs
      .sendForm(SERVICE_ID, TEMPLET_ID, form.current, PUBLIC_KEY)
      .then(
        (response) => {
          setFormSubmitted(true);
          setRelationshipSelected(false);
          setFormData({
            name: '',
            phone: '',
            websiteURL: '',
            location: '',
            email: '',
            company: '',
            relationship: '',
            comments: ''
          });
        },
        (error) => {
          console.log('FAILED...', error);
        }
      );
  };

  // Function to check if a field is required
  const isFieldRequired = (fieldName: string) => {
    return requiredFields.length === 0 || requiredFields.includes(fieldName);
  };

  // Function to get the placeholder for a field, defaulting if not provided
  const getPlaceholder = (fieldName: string, defaultPlaceholder: string) => {
    return placeholders[fieldName] || defaultPlaceholder;
  };

  return (
    <div className="payment-form-container">
      {isModal && <Button startIcon={<CloseOutlined />} onClick={handleCancel}></Button>}
      {title === null ? (
        <p className="form-title-null">
          Ready to get started or learn more about how we can help with all your payment processing needs? Call us at{' '}<a href="tel:+18006832977" style={{ textDecoration: 'none', color: 'black' }}><strong>800.683.2977 </strong></a>or fill out the form below and a company representative will be in touch within 24 hours.
        </p>
      ) : (
        <p className="form-title">{title}</p>
      )}
      <p className="form-subtitle">
        "<span style={{ color: 'red' }}>*</span>" Indicates Required Fields
      </p>
      <form ref={form} onSubmit={sendEmail} className="form-grid">
        {isFieldRequired('name') && (
          <div className="form-group">
            <label htmlFor="name">
              {getPlaceholder('name', 'Name')}
              <span style={{ color: '#FF3B3B' }}>*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
        )}

        {isFieldRequired('email') && (
          <div className="form-group">
            <label htmlFor="email">
              {getPlaceholder('email', 'Email')}
              <span style={{ color: '#FF3B3B' }}>*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
        )}

        {isFieldRequired('phone') && (
          <div className="form-group">
            <label htmlFor="phone">
              {getPlaceholder('phone', 'Phone')}
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
        )}

        {isFieldRequired('company') && (
          <div className="form-group">
            <label htmlFor="company">
              {getPlaceholder('company', 'Company')}
              <span style={{ color: '#FF3B3B' }}>*</span>
            </label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              required
            />
          </div>
        )}

        {isFieldRequired('websiteURL') && (
          <div className="form-group" style={{ position: 'relative' }}>
            <label htmlFor="websiteURL">
              {getPlaceholder('websiteURL', 'Website URL')}
              <span style={{ color: '#FF3B3B' }}>*</span>
            </label>
            <input
              className="web-url-ph"
              type="text"
              id="websiteURL"
              name="websiteURL"
              value={formData.websiteURL}
              onChange={handleChange}
              required
            />
          </div>
        )}

        {isFieldRequired('relationship') && (
          <div className="form-group" style={{ position: 'relative' }}>
            <label htmlFor="relationship">
              {getPlaceholder('relationship', 'Are you an Agent/Partner or Merchant?')}
              <span style={{ color: '#FF3B3B' }}>*</span>
            </label>
            <select
              id="relationship"
              name="relationship"
              value={formData.relationship}
              onChange={handleOptionChange}
              style={{ color: relationshipSelected ? 'black' : '#808080' }}
              required
            >
              <option value="" disabled hidden>
              </option>
              <option value="Agent/Partner">Agent/Partner</option>
              <option value="Merchant">Merchant</option>

            </select>
          </div>
        )}

        {isFieldRequired('location') && (
          <div className="form-group" style={{ position: 'relative' }}>
            <label htmlFor="location">
              {getPlaceholder('location', 'Where is your business located?')}
              <span style={{ color: '#FF3B3B' }}>*</span>
            </label>
            <input
              // placeholder={getPlaceholder('location', 'Where is your business located?')}
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
            />
          </div>
        )}

        {isFieldRequired('comments') && (
          <div className="form-group">
            <label htmlFor="comments">
              {getPlaceholder('comments', 'Additional Comments')}
            </label>
            <input
              // placeholder={getPlaceholder('comments', 'Additional Comments')}
              type="text"
              id="comments"
              name="comments"
              value={formData.comments}
              onChange={handleChange}
            />
          </div>
        )}

        <div>
          {!formSubmitted ? <div className="nst_gateway_signupform_handle_button"> <Button type="submit">Submit</Button></div> : null}
        </div>
      </form>

      {formSubmitted && (
        <div className="thank-you-message" style={{ textAlign: 'center' }}>
          <p>Thank you for your submission!</p>
        </div>
      )}
    </div>
  );
};

export default BusinessForm;
